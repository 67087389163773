import { Button } from "js/components/Elements";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { PrintContent, PrintContentProps } from "./PrintContent";

type PrintButtonProps = PrintContentProps;

const PrintButton = (props: PrintButtonProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const handleResultPrint = useReactToPrint({ contentRef });
  const onClickPrint = () => {
    handleResultPrint();
  };
  const hasFacility = props.facilities.length > 0;

  return (
    <React.Fragment>
      <Button
        color="primary"
        onClick={onClickPrint}
        icon={{ name: "print" }}
        disabled={!hasFacility}
      >
        打診リスト印刷
      </Button>
      {hasFacility && (
        <PrintContent
          ref={contentRef}
          facilities={props.facilities}
          google_map_api_key={props.google_map_api_key}
        />
      )}
    </React.Fragment>
  );
};

export { PrintButton, PrintButtonProps };
